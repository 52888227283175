import {StyledEngineProvider} from '@mui/material/styles';
import {LicenseInfo} from '@mui/x-license';
import * as Sentry from '@sentry/react';
import {createRoot} from 'react-dom/client';

import App from './App';
import {APP_ENVIRONMENT} from './constants';

LicenseInfo.setLicenseKey(
  '10084007753d8e7b1f18ae7ba42dfb1bTz05MTE5OCxFPTE3NDgwOTcyOTYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

Sentry.init({
  enabled: ['production', 'staging'].includes(APP_ENVIRONMENT),
  ignoreErrors: [
    'Invalid authentication credentials: Signature has expired.',
    'A project with this slug exists already',
  ],
  environment: APP_ENVIRONMENT,
  dsn: 'https://76102263e94d4fe4cdf104e96a5e16b9@us.sentry.io/4506700312412160',
  tracePropagationTargets: [
    /^https:\/\/api\.prod\.trajaan\.io/,
    /^https:\/\/api\.staging\.trajaan\.io/,
  ],
  integrations: [
    Sentry.browserTracingIntegration({}),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, //  Capture 10% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = createRoot(document.getElementById('root'));
root.render(
  <StyledEngineProvider injectFirst>
    <App />
  </StyledEngineProvider>,
);
